@tailwind base;
@tailwind components;
@tailwind utilities;

.outlineText {
  color: #FFF;
  text-shadow:
   -1px -1px 0 #690d94,  
    1px -1px 0 #690d94,
    -1px 1px 0 #690d94,
     1px 1px 0 #690d94;
}
